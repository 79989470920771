<template>
  <v-container>

      <h3 class="display-1 text-primary-color font-weight-black  my-5 text-center text-md-start" style="word-break: break-word;" v-if="$vuetify.breakpoint.md">Mis postulaciones</h3>
      <h5 v-else-if="$vuetify.breakpoint.sm" class="display-1 text-primary-color font-weight-black my-5 text-center text-md-start">Mis postulaciones</h5>
      <h6 v-else class="display-1 text-primary-color font-weight-black  my-5 text-center text-md-start">Mis postulaciones</h6>
    <!-- <header>
      <h1 class="display-2 text-primary-color font-weight-black text-capitalize my-5">mis postulaciones</h1>
    </header> -->
    <!-- <main>
      <div class="postulaciones">
        <div class="postulacion" v-for="(postulacion) in postulaciones" :key="postulacion.id">
          <CardEmpleo :item="postulacion" @on-click-detalle="goToDetalle" />
        </div>
      </div>
    </main> -->
    <ChatComponent />
    <v-row >
      <v-col cols="12" md="6" lg="4" xl="3"  v-for="(postulacion) in ListPostulaciones" :key="postulacion.id">
          <CardEmpleo :item="postulacion"  v-on:actualizarOfertas="getListPostulaciones" />
      </v-col>
    </v-row>
    <v-row justify="center">
            <!-- Mostrar imagen y texto si no hay postulaciones -->
      <div v-show="!ListPostulaciones || ListPostulaciones.length === 0" class="text-center my-3"  >
      <div class="d-flex flex-column align-items-center">
        <img src="../../assets/img/NoInvitacionesRRHH.png" class="mx-auto" style="max-width: 60%" />
        <h2>No tienes postulaciones disponibles por el momento.</h2>
      </div>
    </div>
  </v-row>

    <app-loader v-if="loading"></app-loader>
  </v-container>
</template>
<script>
import CardEmpleo from '../../components/CardEmpleo.vue'
import ChatComponent from "../../components/ChatComponent.vue";
import AppLoader from "@/components/AppLoader.vue";
import {mapMutations, mapState} from "vuex";
export default {
  name: 'MisPostulaciones',
  components: {
    AppLoader,
    CardEmpleo,
    ChatComponent
  },
  data() {
    return {
      ListPostulaciones:{},
      // información de prueba
      loading: false,
    }
  },
  methods: {


    async getListPostulaciones(){
      if(this.isTokenValido(this.token)) {
        // if(this.tieneRoles(this.token)) {
          this.loading = true;
          try {
            
            if(typeof this.userInfo.id_persona !== 'undefined'){
              const response = await this.services.postulaciones.getPostulaciones(this.userInfo.id_persona)
              // console.log({response})
              if (response.status == 200) {
                this.ListPostulaciones = response?.data

              }
              setTimeout(() => {
                this.loading = false;
              }, 500);
            }
          }catch (e) {

          }finally {

          }
        // } else {
        //   this.temporalAlert({
        //     show:true,
        //     type:"error",
        //     message:"No tiene permiso para listar las postulaciones"
        //   })
        // }

      } else {
        this.temporalAlert({
          show:true,
          type:"error",
          message:"El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }
    }
  },
  computed: {
    ...mapState(["userInfo", "token"]),
  },
  created(){
    this.loading = true;
    setTimeout(async () => {
      await this.getListPostulaciones()
      this.loading = false;
    }, 1000);




  }

}

</script>
<style scoped>
 /* .postulaciones{
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 1rem;
 } */

.text-primary-color {
  color: #22234a !important;
}



</style>
