<template>
  <v-row>
    <v-col cols="12" >
      <v-card class="rounded-xl mb-5" elevation="5" style="height: 302px">
        <v-row>
          <v-col cols="12">
            <!-- cabecera de la tarjeta -->
            <div class="rounded-xl mx-2 card-header--style" :style="{ backgroundColor: getBg()}">
              <v-app-bar color="transparent" dense flat class="rounded-xl pb-0 pt-0">
                <v-chip class="white text-caption">
                  {{ item.created_ate != null ? moment(item.created_ate).format('ll') : '' }}
                </v-chip>
                <v-spacer></v-spacer>
                <br>
                <v-chip icon style="cursor: pointer;" @click="gestionarFavorito(item)" v-show="mostrar!=null">
                  <v-icon color="#22234a" small>
                    {{
                     item.fav_icon
                    }}
                  </v-icon>
                </v-chip>

              </v-app-bar>
              <div color="transparent" dense flat class="rounded-xl ml-4">
                <v-chip :color="getColorEstado(item?.oferta_personas[0]?.nombre_estado_oferta_persona)" dark v-if="$route.path !== '/oferta-empleos'">
                  {{ item?.oferta_personas[0]?.nombre_estado_oferta_persona }}
                </v-chip>
              </div>
              <footer class="pt-0 px-5">
                <div class="body-1 text-capitalize">{{ item.solicitud?.UnidadOrganizativa?.nombre }}</div>
                <div class="text-capitalize text-primary-color"> {{ item.solicitud?.titulo_oferta }}</div>
              </footer>
            </div>

            <!-- cuerpo de la tarjeta -->
            <v-card-text>
              <div>
                <p class="d-flex pb-0 " v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">

                  <v-chip class="mr-1  " outlined v-for="(element, i) in item.palabras_claves.slice(0,2)" :key="i">
                    <span class="text-caption">{{ element.nombre_palabra_clave }}</span>
                  </v-chip>
                </p>
                <p class="d-flex pb-0" v-else>

                  <v-chip class="mr-1 text-caption" outlined
                          v-for="(element, i) in item.palabras_claves.slice(0,2)" :key="i">
                    <span class="text-caption">{{ element.nombre_palabra_clave }}</span>
                  </v-chip>
                </p>
              </div>
              <footer class="mt-5">
                <div class="d-flex flex-end">
                  <div class="flex mb-1 mr-2 mt-2">
                    <div class="caption">{{ item?.solicitud?.UnidadOrganizativa?.estable?.muni?.Departamento?.nombre }},
                      El Salvador
                    </div>
                  </div>
                  <div>
                    <v-btn class="caption text-capitalize white--text bg-primary-color" rounded
                           @click="goToDetalle(item.id)"
                    >Detalles
                    </v-btn>
                  </div>
                </div>
              </footer>

            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <app-loader v-if="loading"></app-loader>
  </v-row>
</template>

<script>
import AppLoader from "@/components/AppLoader.vue";
import {mapState} from 'vuex'

export default {
  name: 'Carditem',
  components: {AppLoader},
  props: {
    item: {
      type: Object,
      required: true
    },
    // persona: {
    //   type: Number,
    //   required: true
    // },
    // favoritos:{
    //   type: Boolean,
    //   required: true
    // }
  },
  data: () => ({
    message : null,
    mostrar: localStorage.getItem('token'),
    // colores de fondo para las tarjetas
    bgCards: [
      '#d2d2db',
      '#e3d3c8',
      '#f6f6f6',
    ],
    favorito: false,
    loading: false,
  }),
  watch: {
    item(newVal) {
      this.loading = false
      // Aquí puedes realizar la lógica adicional que necesites cuando cambie la prop `item`
    },
    loading(newVal) {
      if (!newVal) {
        // Cuando `loading` se establece en `false`, mostrar la alerta
        this.temporalAlert({
          show: true,
            type: "success",
            message:this.message // Puedes usar `response.data.message` si lo prefieres
        });
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'token']),

    iconoFavorito() {
      if (this.item.favoritos?.length === 0) {
        return 'mdi-heart-outline';
      } else if (this.item.favoritos[0].activo === true) {
        return 'mdi-heart';
      } else {
        return 'mdi-heart-outline';
      }
    }
   
  },
  methods: {
    // método para obtener un color aleatorio
    getBg() {
      return this.bgCards[Math.floor(Math.random() * this.bgCards?.length)];
    },

    goToDetalle(id) {
      this.$router.push("/empleo/" + id)
    },

    getColorEstado(estado) {
      if (estado == 'En contratación') return "#44c4b1";
      else if (estado == 'Pendiente') return "blue";
      else if (estado == 'Contratado') return "#1C1E4D";
      else if (estado == 'Firma de contrato') return "#F3A723";
      else if (estado == 'Evaluado') return "#7B61FF";
      else if (estado == 'En proceso') return "#2D52A8";
      else if (estado == 'Declinado') return "red";
    },


    async gestionarFavorito(item) {
    if (this.isTokenValido(this.token)) {
      this.loading = true;
      const body = {
        id_oferta: item.id,
        id_persona: this.userInfo.id_persona
      };
      try {
        const response = await this.services.favoritos.putFavorito(body);
        if (response.status === 201) {
          this.message = response.data.message
         /* this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message
          });*/
          // Emitir el evento para actualizar la lista de ofertas
          this.$emit('actualizarOfertas');
          this.loading = false;
        }
      } catch (e) {
        let errorMessage = "Ocurrió un error";
        if (e.response && e.response.data && e.response.data.err) {
          errorMessage = e.response.data.err.message || e.response.data.err.description;
        }
        this.temporalAlert({
          show: true,
          type: "error",
          message: errorMessage
        });
      } finally {
        setTimeout(() => {
        }, 100);
      }
    } else {
      this.temporalAlert({
        show: true,
        type: "error",
        message: "El token ya expiró, debe de volver a iniciar sesión"
      });
      this.$router.replace({ name: "login" });
    }
  },
  },
}

</script>

<style>
.bg-primary-color {
  background-color: #22234a !important;
}

.text-primary-color {
  color: #22234a !important;
}

.card-header--style {
  height: 155px;
}

.my-scroll::-webkit-scrollbar {
  width: 3px; /* Cambia el ancho del scrollbar */
}

.my-scroll::-webkit-scrollbar-thumb {
  background-color: #22234a; /* Cambia el color del thumb del scrollbar */
}

.my-scroll::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Cambia el color del track del scrollbar */
}


</style>
