<template>
  <div class="article rounded-lg pt-8 mt-9" >
    <div class="icon rounded-circle d-flex justify-center">
      <v-icon class="white--text" x-large>{{ icon }}</v-icon>
    </div>
    <div class="pa-4 body-2 text-primary-color">
      <p class="title font-weight-bold"><strong>{{ title }}</strong></p>
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Article',
  props: {
    icon: String,
    title: String

  }
}
</script>
<style>
.article {
  background-color: #F6F6F6;
  position: relative;
}
.icon{
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #22234a;
  width: 4em;
  height: 4em;
}
.text-primary-color{
  color: #1C1E4D !important;
}
</style>