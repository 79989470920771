<template>
  <v-container>
  <app-loader v-if="loading"></app-loader>
  <div style="background-color: white" v-if="dataLoaded">
    <div class="container-header">
      <v-img src="../../assets//img//Vector6.png">
        <v-row class="pb-3">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-row>
              <v-col cols="12" sm="12" md="10" class="componente-posicion">
                <p
                  :class="$vuetify.breakpoint.lgAndUp ? 'titulo' : 'text-h6'"
                  class="ma-3"
                >
                  {{ DetallePuesto?.solicitud.UnidadOrganizativa?.nombre }}
                </p>
                <p
                  :class="$vuetify.breakpoint.lgAndUp ? 'text-h2' : 'text-h4'"
                  class="text-primary-color pt-0 ma-3 no-corte"
                  style="font-weight: bold"
                >
                  <strong>SOMETE A CONCURSO {{DetallePuesto?.oferta_bitacora[0]?.TipoConcurso?.nombre_concurso.toUpperCase()}}</strong>
                </p>
                <p
                  :style="
                    $vuetify.breakpoint.lgAndUp ? 'line-height: 1.1;' : ''
                  "
                  :class="$vuetify.breakpoint.lgAndUp ? 'titulo' : 'text-h6'"
                  class="pt-0 ma-3"
                >
                  {{ DetallePuesto?.solicitud?.titulo_oferta }}
                </p>
                <!--                && this.Postulacion.hasOwnProperty('id_tipo_aplicacion') && this.Postulacion?.id_tipo_aplicacion !== 2-->
                
                <v-btn
                  :x-large="$vuetify.breakpoint.lgAndUp ? true : false"
                  color="bg-primary-color"
                  rounded
                  dark
                  style="text-transform: none; margin-bottom: 0.4cm; margin-left: 14px;"
                  @click="abrirModal()"
                  v-if="
                    this.Postulacion?.id_estado_oferta_persona === null ||
                    this.Postulacion?.id_estado_oferta_persona === undefined ||
                    (this.Postulacion === null &&
                      this.Postulacion?.id_tipo_aplicacion !== 2)
                  "
                >
                  Aplicar al empleo
                  <v-icon class="ml-1">mdi-check-circle-outline</v-icon>
                </v-btn>
                
                <v-chip
                  color="gray"
                  dark
                  v-if="
                    this.Postulacion?.id_tipo_aplicacion === 2 &&
                    this.Postulacion.id_estado_oferta_persona === 2
                  "
                >
                  <span class="ma-2" style="font-size: 1rem; margin-left: 14px;"
                    >Invitación rechazada</span
                  >
                </v-chip>
                <v-chip
                style="margin-left: 14px;"
                  color="blue"
                  dark
                  v-if="
                    this.Postulacion?.id_estado_oferta_persona !== null &&
                    this.Postulacion?.id_estado_oferta_persona !== undefined &&
                    this.Postulacion.id_estado_oferta_persona === 3
                  "
                >
                  Postulación enviada
                </v-chip>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-img src="../../assets/img/detalle.svg"></v-img>
          </v-col>
        </v-row>
      </v-img>
    </div>
    <ChatComponent />
    <!-- 
      <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="d-flex justify-center align-center"
      >
        <v-row class="d-flex justify-center" style="margin-left: 65px">
          <v-col cols="2" md="1" class="pr-1 mr-3">
            <div class="bg-primary-color rounded-circle icon-puesto">
              <v-icon class="white--text mt-2 ml-2" size="45"
                >mdi-file-document-outline
              </v-icon>
            </div>
          </v-col>
          <v-col cols="6" md="6" class="pl-0">
            <div class="display-1 responsiveDescriptionTitle">
              <h4
                class="headline-1"
                style="white-space: nowrap; color: #22234a"
              >
                Descripción
              </h4>
              <h4
                class="headline-1"
                style="white-space: nowrap; color: #22234a"
              >
                del puesto
              </h4>
            </div>
          </v-col>
        </v-row>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class=".text-primary-color d-flex align-center justify-center responsiveDescription"
          style="margin: 0.4cm"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          {{ DetallePuesto?.solicitud?.MNTCargoFuncional?.descripcion }}
        </v-col>
        <v-col
          cols="6"
          sm="12"
          md="6"
          class=".text-primary-color d-flex align-center justify-center responsiveDescription"
          style="margin: 0.4cm"
          v-else
        >
          {{ DetallePuesto?.solicitud?.MNTCargoFuncional?.descripcion }}
        </v-col>
      </v-col>
    </v-row>
-->
    <!-- Inicia la descripcion de los puestos de trabajo -->
    <v-container>
    <!-- <v-row> -->
      <v-row class="descriptionRow">
      <!-- <v-row class="d-flex justify-center" style="margin-left: 65px"> -->
          <v-col class="descriptionContainer">
            <div class="bg-primary-color rounded-circle icon-puesto">
              <v-icon class="white--text mt-2 ml-2" size="45"
                >mdi-file-document-outline
              </v-icon>
            </div>
          <!-- <v-col cols="6" md="6" class="pl-0"> -->
            <div class="display-1 responsiveDescriptionTitle">
            <!-- <div class="display-1 responsiveDescriptionTitle"> -->
              <h4
                class="headline-1"
                style="white-space: nowrap; color: #22234a"
              >
                Descripción
              </h4>
              <h4
                class="headline-1"
                style="white-space: nowrap; color: #22234a"
              >
                del puesto
              </h4>
            </div>
          </v-col>
        <v-col
          class="text-primary-color sizeLetter descriptionContainer"
          style="margin: 0.4cm"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          {{ DetallePuesto?.solicitud?.MNTCargoFuncional?.descripcion }}
        </v-col>
        <v-col
          class="text-primary-color sizeLetter descriptionContainer"
          style="margin: 0.4cm"
          v-else
        >
          {{ DetallePuesto?.solicitud?.MNTCargoFuncional?.descripcion }}
        </v-col>
        </v-row>
      <v-row>
      <!-- <v-col cols="12" sm="12" md="12" class="d-flex justify-center align-center"> -->
      </v-row>
    </v-container>
    <!-- Finaliza la descripcion de los puestos de trabajo -->

      <v-row class="pl-4 pt-6">
      <div class="text-primary-color mx-7 font-weight-bold text-h5">
        Palabras claves
      </div>
      <v-tabs show-arrows hide-slider color="white" class="mt-2">
        <v-tab v-for="(item, i) in DetallePuesto?.palabras_claves" :key="i">
          <v-chip
            class="mr-1 caption"
            style="text-transform: none; margin: 0.3cm"
            outlined
          >
            {{ item.nombre_palabra_clave }}
          </v-chip>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="mt-7 ml-2 mr-2">
      <v-col cols="12" md="6">
        <Article
          icon="mdi-account-hard-hat-outline"
          title="Actividades"
          style="height: 92%"
        >
          <ul>
            <li
              v-for="(item, index) in DetallePuesto?.solicitud
                ?.MNTCargoFuncional?.CargoActividads"
              :key="index"
              class="espaciadoLista"
            >
              {{ item?.descripcion }}
            </li>
          </ul>
        </Article>
      </v-col>
      <v-col cols="12" md="6">
        <Article
          icon="mdi-seal"
          title="Requisitos indispensables"
          style="height: 92%; width: 100%"
        >
          <div class="text-primary-color font-weight-bold text-h6 pb-2">
            Formación
          </div>
          <ul>
            <li
              v-for="(item, index) in formacionExtraNombreIndispensable"
              :key="index"
              class="espaciadoLista"
            >
              {{ item?.nombre }}
            </li>
          </ul>
          <div class="text-primary-color font-weight-bold text-h6 pb-2">
            Conocimiento
          </div>
          <ul>
            <li
              v-for="(item, index) in conocimientosExtraNombreIndispensable"
              :key="index"
              class="espaciadoLista"
            >
              {{ item?.nombre }}
            </li>
          </ul>
        </Article>
      </v-col>
      <v-col cols="12" md="6">
        <v-col cols="12" md="12" class="ma-0 pa-0">
          <Article
            icon="mdi-text-account"
            title="Indicaciones"
            style="height: 92%; width: 100%"
          >
            <p v-html="DetallePuesto?.modalidad_aplicacion"></p>
          </Article>
        </v-col>
        <v-col cols="12" md="12" class="ma-0 pa-0">
          <Article
            icon="mdi-handshake-outline"
            title="Prestaciones"
            style="height: 92%; width: 100%"
          >
            <p v-html="DetallePuesto?.se_ofrece"></p>
            <li v-if="DetallePuesto?.muestra_salario">
              <strong>Salario:</strong>
              {{
                $currency.format(DetallePuesto?.solicitud?.salario_propuesto)
              }}
            </li>
          </Article>
        </v-col>
        <v-col cols="12" md="12" class="ma-0 pa-0">
          <Article
            icon="mdi-briefcase-clock-outline"
            title="Experiencia previa"
            style="width: 100%"
          >
            <ul>
              <li
                v-for="item in experienciaPrevia"
                :key="item.id"
                class="espaciadoLista"
              >
                {{ item?.nombre }} de {{ item?.NivExp?.anios }}
                {{item?.anios}} 
              </li>
            </ul>
          </Article>
        </v-col>
      </v-col>
      <v-col cols="12" md="6">
        <v-col cols="12" md="12" class="ma-0 pa-0">
          <Article
            icon="mdi-seal"
            title="Requisitos deseables"
            style="height: 600px; width: 100%"
          >
            <div class="text-primary-color font-weight-bold text-h6 pb-2">
              Formación
            </div>
            <ul>
              <li
                v-for="(item, index) in formacionExtraNombreDeseable"
                :key="index"
                class="espaciadoLista"
              >
                {{ item?.nombre }}
              </li>
            </ul>
            <div class="text-primary-color font-weight-bold text-h6 pb-2">
              Conocimiento
            </div>
            <ul>
              <li
                v-for="(item, index) in conocimientosExtraNombreDeseable"
                :key="index"
                class="espaciadoLista"
              >
                {{ item?.nombre }}
              </li>
            </ul>
            <div class="text-primary-color font-weight-bold text-h6 pb-2">
              Opciones extras
            </div>
            <ul>
              <li v-for="(item, index) in detallesOpcionesExtras" :key="index" class="espaciadoLista">

                {{ item?.descripcion }}
              </li>
            </ul>
            <div class="text-primary-color font-weight-bold text-h6 pb-2">
              Otros aspectos
            </div>
            <ul>
              <li v-for="(item,index) in DetallePuesto?.solicitud?.MNTCargoFuncional.CargoAspectos" :key="index" class="espaciadoLista">
                {{ item.nombre_aspecto}}
              </li>
            </ul>
          </Article>
        </v-col>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mt-16 mb-5">
      <v-btn
        color="bg-primary-color"
        dark
        rounded
        style="text-transform: none"
        @click="regresar"
      >
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Regresar
      </v-btn>
    </v-row>
    <v-dialog v-model="modalAplicar" persistent max-width="600px">
      <v-card>
        <v-container>
          <v-row class="d-flex justify-center mt-9 mb-3 pl-2">
            <v-col cols="12 text-center">
              <span class="text-h5 text-secondary-color"
                ><b>¿Estás seguro de aplicar esta oferta de empleo?</b></span
              >
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-3">
            <v-col cols="6" sm="3">
              <v-btn
                color="bg-primary-color"
                class="ml-5"
                outlined
                rounded
                @click="modalAplicar = false"
                style="text-transform: none"
                >Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6" sm="2">
              <v-btn
                color="bg-primary-color"
                rounded
                @click="Postularse()"
                dark
                style="text-transform: none"
                >Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalEmpleo" persistent max-width="600px">
      <v-card>
        <v-card-title class="d-flex justify-center">
          <v-icon color="#22234a" size="80">mdi-check-circle-outline</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center mt-5 mb-3">
            <v-col cols="12">
              <span class="text-h4 text-secondary-color"
                ><b>¡Te has postulado correctamente!</b></span
              >
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-3">
            <v-col cols="6" sm="3">
              <v-btn
                color="bg-primary-color"
                dark
                rounded
                @click="cerrarModal()"
                large
                style="text-transform: none"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <app-loader v-if="loading"></app-loader>
    <ModalRegistrarmeComponent
      :modal="modalRegistro"
      @cerrar="cerrarModalRegistro()"
    />
  </div>
</v-container>
</template>
<script>
import Article from "../../components/Article.vue";
import AppLoader from "@/components/AppLoader.vue";
import { mapState } from "vuex";
import ModalRegistrarmeComponent from "../landingPage/components/modalRegistrarmeComponent.vue";
import AppFooter from "../../components/AppFooter.vue";
import ChatComponent from "../../components/ChatComponent.vue";

export default {
  name: "Detalle",
  components: {
    ModalRegistrarmeComponent,
    AppLoader,
    Article,
    AppFooter,
    ChatComponent,
  },
  data() {
    return {
      Postulacion: null,
      DetallePuesto: null,
      modalAplicar: false,
      modalEmpleo: false,
      loading: false,
      modalRegistro: false,
      id_persona: false,
      dataLoaded: false,
      model: null,
      detallesOpcionesExtras: [],
      formacionExtraNombreDeseable: [],
      formacionExtraNombreIndispensable: [],
      conocimientosExtraNombreDeseable: [],
      conocimientosExtraNombreIndispensable: [],
      experienciaPrevia: [],
    };
  },
  computed: {
    movil() {
      if (screen.width < 1024) {
        return false;
      } else {
        return true;
      }
    },
    ...mapState(["userInfo", "token"]),
  },
  methods: {
    async allFormaciones() {
      this.loading = true;
      let id_oferta = this.$router.history.current.params.id;
      try {
        const responseAll = await this.services.OfertaEmpleo.getFormacionAll(
          id_oferta
        );
        const response = await this.services.OfertaEmpleo.getFormacionesExtras(
          id_oferta
        );
        if (responseAll.status == 200) {
          if (responseAll.data.length > 0) {
            responseAll.data.forEach((element) => {
              if (element?.IndispensableFormacionOferta.length > 0) {
                element.IndispensableFormacionOferta.forEach((element2) => {
                  if(element2?.indispensable===false){
                    this.formacionExtraNombreDeseable.push({
                      nombre: element.FormacionEducativa.nombre
                    });
                  }else{
                    this.formacionExtraNombreIndispensable.push({
                      nombre: element.FormacionEducativa.nombre
                    });
                  }
                });
              } else {
                this.formacionExtraNombreDeseable.push({
                  nombre: element.FormacionEducativa.nombre
                });
              }
            });
          }
        }
        if (response.status == 200) {
          if (response.data.Formaciones.length > 0) {
            response.data.Formaciones.forEach((element) => {
              if (element?.indispensable === false) {
                this.formacionExtraNombreDeseable.push({
                  nombre: element?.nombre,
                });
              } else {
                this.formacionExtraNombreIndispensable.push({
                  nombre: element?.nombre,
                });
              }
            });
          }
        }
      } catch (e) {
        // console.log("error", e);
      } finally {
        this.loading = false;
      }
    },

    async allConocimientos() {
      this.loading = true;
      let id_oferta = this.$router.history.current.params.id;
      try {
        const responseAll = await this.services.OfertaEmpleo.getConocimientoAll(
          id_oferta
        );
        const response = await this.services.OfertaEmpleo.getConocimientosExtras(
          id_oferta
        );
        if (responseAll.status == 200) {
          if (responseAll.data.length > 0) {
            responseAll.data.forEach((element) => {
              if (element?.IndispensableConocimientoOferta.length > 0) {
                element.IndispensableConocimientoOferta.forEach((element2) => {
                  if(element2?.indispensable===false){
                    this.conocimientosExtraNombreDeseable.push({
                      nombre: element.Conocimiento.nombre
                    });
                  }else{
                    this.conocimientosExtraNombreIndispensable.push({
                      nombre: element.Conocimiento.nombre
                    });
                  }
                });
              } else {
                this.conocimientosExtraNombreDeseable.push({
                  nombre: element.Conocimiento.nombre
                });
              }
            });
          }
        }
        if (response.status == 200) {
          if (response.data.conocimientos.length > 0) {
            response.data.conocimientos.forEach((element) => {
              if (element?.indispensable === false) {
                this.conocimientosExtraNombreDeseable.push({
                  nombre: element?.nombre,
                });
              } else {
                this.conocimientosExtraNombreIndispensable.push({
                  nombre: element?.nombre,
                });
              }
            });
          }
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    abrirModal() {
      if (!this.usuarioAutenticado()) {
        this.modalRegistro = true;
        return;
      }
      this.modalAplicar = true;
    },
    abrirAplicarEmpleo() {
      this.modalEmpleo = true;
    },
    cerrarModal() {
      this.modalAplicar = false;
      this.modalEmpleo = false;
    },

    async DetalleEmpleo() {

      this.loading = true;
      let id_oferta = this.$router.history.current.params.id;
      try {
        const response = await this.services.OfertaEmpleo.getDetalleEmpleo(
          id_oferta
        );
        
        if (response.status == 200) {
          this.DetallePuesto = response.data;
          if (this.DetallePuesto?.solicitud?.DetalleOpcionExtras) {
            this.DetallePuesto.solicitud.DetalleOpcionExtras.forEach(
              (element) => {
                this.detallesOpcionesExtras.push(element);
              }
            );
          }
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    async ExperienciaPreviaAll() {
      this.loading = true;
      let id_oferta = this.$router.history.current.params.id;
      try {
        const responseAllExperience= await this.services.OfertaEmpleo.getDetalleEmpleo(
          id_oferta
        );
       
        const response = await this.services.OfertaEmpleo.getExperenciaAll(
          id_oferta
        );
        if (responseAllExperience.status == 200) {
          
          if (responseAllExperience.data.solicitud.expPre.length > 0) {
            
            responseAllExperience.data.solicitud.expPre.forEach((element) => {
              
              const aniosViejo = element.NivExp.anios.replace(/\b(?:de|De)\b/gi, '').trim();
              this.experienciaPrevia.push({
                nombre: this.capitalizeFirstLetter(element.cargo_experiencia.nombre),
                anios: aniosViejo,
              });
            });
            
          }
          
        }
        if(response.status == 200){
          if (response.data.Formaciones.length > 0) {
            response.data.Formaciones.forEach((element2) => {
              
              const aniosOther= element2.nivel.replace(/\b(?:de|De)\b/gi, '').trim();
              this.experienciaPrevia.push({
                nombre: this.capitalizeFirstLetter(element2.nombre),
                anios: aniosOther,
              });
            });
          }
        }
        
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    async Postularse() {
      if (this.isTokenValido(this.token)) {
        let id_oferta = this.$router.history.current.params.id;
        try {
          this.loading = true;
          const response = await this.services.OfertaEmpleo.postPostularse(
            id_oferta,
            this.id_persona
          ).catch(() => {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "La oferta ya ha finalizado",
            });
            this.modalAplicar = false;
          });

          if (response.status == 200) {
            this.modalAplicar = false;
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
            //.log("la persona se postulo correctamente",response.data.message)
          }
          await this.getValidacionPostulacion();
        } catch (e) {
        } finally {
          this.loading = false;
        }
      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión",
        });
        this.$router.replace({ name: "login" });
      }
    },

    async getValidacionPostulacion() {
      let id_oferta = this.$router.history.current.params.id;
      try {
        this.loading = true;
        const response = await this.services.OfertaEmpleo.getPostulacion(
          id_oferta,
          this.id_persona
        );
        if (response.status == 200) {
          this.Postulacion = response?.data[0];
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    regresar() {
      this.$router.back();
    },
    cerrarModalRegistro() {
      this.modalRegistro = false;
    },
  },

  async created() {
    if (this.token) {
      this.id_persona = this.userInfo.id
      this.loading = true;
      setTimeout(async() => {
        await this.getValidacionPostulacion();
        // await this.DetalleEmpleo();
        this.loading = false;
      }, 2000);
    }
    try {

      await this.DetalleEmpleo();
      await this.allFormaciones();
      await this.allConocimientos();
      await this.ExperienciaPreviaAll();
    } catch (error) {
      console.error(error);
    } finally {
      this.dataLoaded = true;
    }
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .headline-1 {
    font-size: 2rem; /* Ajusta este valor según sea necesario par2a dispositivos móviles */
  }
}

.bg-primary-color {
  background-color: #22234a !important;
}

.text-primary-color {
  color: #22234a !important;
}

.container-header {
  top: 0 !important;
  width: 100%;
  font-size: 12px;
}

.text-secondary-color {
  color: #263238;
}

.wave {
  background: url(../../assets/img/wave.svg);
  width: 100% !important;
  background-size: cover;
  padding-bottom: 160px;
}

.row-position {
  padding-top: 100px;
}

.icon-puesto {
  position: relative;
  top: 35px;
  left: 5%;
  transform: translate(-50%, -50%);
  background-color: #22234a;
  width: 4em;
  height: 4em;
  margin: 1rem;
}

.componente-posicion {
  margin-left: 125px;
  margin-top: 106px;
}
/* .componente-posicion {
  margin-left: 150px;
  margin-top: 200px;
} */

.no-corte {
  white-space: normal;
}

.espaciadoLista{
  margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .componente-posicion {
    margin-left: 20px;
    margin-top: 25px;
  }
}

.titulo {
  font-size: 2em !important;
}

@media (min-width: 600px) {
  .titulo {
    font-size: 3em !important;
  }
}

@media (min-width: 900px) {
  .titulo {
    font-size: 2em !important;
  }
}

@media (min-width: 1320px) {
  .titulo {
    font-size: 2em !important;
  }
}

@media (min-width: 1500px) {
  .titulo {
    font-size: 3.6em !important;
  }
}
@media (max-width: 1400px) {
  .responsiveDescriptionTitle {
    margin-left: 6px !important;
  }
}
.descriptionContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.descriptionRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .descriptionRow {
    flex-direction: row;
  }
}
.sizeLetter{
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .sizeLetter {
    font-size: 1.3rem;
  }
}

</style>
